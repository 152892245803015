import React from "react";
import "./Footer.scss";
import { NavLink } from "react-router-dom";
import { logo, qualificationLogo } from "../../assets/assetsExport";
import { companyInfo } from "../../data/general";

export default function Footer() {
  return (
    <div className="footer-container bg-dark a-no-decor cl-light">
      <NavLink to="/"><img src={logo.light} alt="Letting Engine logo" /></NavLink>
      <div>
        <ul>
          <li>
            <NavLink to="/services/rent">Rent</NavLink>
          </li>
          <li>
            <NavLink to="/services/landlords">Landlords</NavLink>
          </li>
          <li>
            <NavLink to="/about-us">About Us</NavLink>
          </li>
          <li>
            <NavLink to="/contact-us">Contact Us</NavLink>
          </li>
          <li>
            <NavLink to="/careers">Career</NavLink>
          </li>
        </ul>
        <ul>
          <li>Email: <a href={`mailto:${companyInfo.email}`}>{companyInfo.email}</a></li>
          <li>{companyInfo.phone}</li>
          <li>
            {companyInfo.address.split(";").map(i => {
              return <span>{i}<br /></span>;
            })}
          </li>
        </ul>
        <ul>
          <li><NavLink to="/policies/terms-and-conditions">Terms & Conditions</NavLink></li>
          <li><NavLink to="/policies/privacy-policy">Privacy Policy</NavLink></li>
          <li><NavLink to="/policies/cookies-policy">Cookies Policy</NavLink></li>
        </ul>
      </div>
      <div className="copyright">
        <div className="text">
          <p>&copy; Copyright {new Date().getFullYear()} Letting Engine</p>
          {/* <p>Letting Engine is a business name used by New Vision Real Estate Limited, registered in England and Wales. Company No.: 12987204. VAT Number: 386337268.</p> */}
        </div>
        <div className="logo">
          <p>LETTING ENGINE IS A MEMBER OF</p>
          <img src={qualificationLogo.arla} alt="alra logo" />
          <img src={qualificationLogo.cmp} alt="cmp logo" />
          <img src={qualificationLogo.prs} alt="prs logo" />
          <img src={qualificationLogo.nrla} alt="nrla logo" />
        </div>
      </div>
    </div>
  );
}
