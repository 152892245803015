import application from "./media/illustrations/application.svg";
import search from "./media/illustrations/search.svg";
import support from "./media/illustrations/support.svg";
import viewing from "./media/illustrations/viewing.svg";

export const homeHeroImg = [
  { src: "", alt: "" },
  { src: "", alt: "" },
  { src: "", alt: "" },
];

export const logo = {
  light: require("./media/logo/letting-engine-logo-light.png"),
  dark: require("./media/logo/letting-engine-logo-dark.png"),
};

export const lettingProcedure = require("./document/Letting Engine Landlord Fees and Charges.pdf");
export const lettingVacant = require("./document/LE VACANT PROPERTY MANAGEMENT PRICE LIST.pdf");

export const qualificationLogo = {
  arla: require("./media/logo/qualification/arla.png"),
  nrla: require("./media/logo/qualification/nrla.png"),
  prs: require("./media/logo/qualification/prs.png"),
  cmp: require("./media/logo/qualification/cmp.png"),
};
// export const backgroundImg = [
//   require("./media/background/1.png"),
//   require("./media/background/2.png"),
//   require("./media/background/3.png"),
//   require("./media/background/4.png"),
//   require("./media/background/5.png"),
//   require("./media/background/6.png"),
//   require("./media/background/7.png"),
// ];

export const backgroundImg = {
  livingRoom1: require("./media/background/living-room1.jpg"),
  livingRoom2: require("./media/background/living-room2.jpg"),
  livingRoom3: require("./media/background/living-room3.jpg"),
  livingRoom5: require("./media/background/living-room5.jpg"),
  livingRoom4: require("./media/background/living-room4.jpg"),
  meeting1: require("./media/background/meeting1.jpg"),
  social: require("./media/background/social-event.jpg"),
  meeting2: require("./media/background/meeting2.jpg"),
};
// export const backgroundImg = [
//   require("./media/background/living-room1.jpg"),
//   require("./media/background/living-room2.jpg"),
//   require("./media/background/living-room3.jpg"),
//   require("./media/background/istockphoto-878534316-1024x1024.jpg"),
//   require("./media/background/istockphoto-489606922-1024x1024.jpg"),
//   require("./media/background/living-room5.jpg"),
//   require("./media/background/living-room4.jpg"),
//   require("./media/background/meeting1.jpg"),
//   require("./media/background/social-event.jpg"),
//   require("./media/background/meeting2.jpg"),
// ];

export const rentIllustration = {
  application: application,
  search: search,
  support: support,
  viewing: viewing,
};

export const propertiesImg = {
  mount: require("./media/property/8mount.jpeg"),
  millbank6200: require("./media/property/millbank6200.jpeg"),
  moor17117: require("./media/property/moor17117.jpeg"),
  moor15000: require("./media/property/moor15000.jpeg"),
  one3300: require("./media/property/one3300.jpeg"),
  one4200: require("./media/property/one4200.jpeg"),
  one4500: require("./media/property/one4500.jpeg"),
  one3700: require("./media/property/one3700.jpeg"),
  millbank6000: require("./media/property/millbank6000.jpeg"),
  markville: require("./media/property/markville.jpeg"),
  blackfriars: require("./media/property/blackfriars.jpeg"),
  parrs: require("./media/property/parrs.jpeg"),
  blenheim: require("./media/property/blenheim.jpeg"),
  allium: require("./media/property/allium.jpeg"),
  bentinck: require("./media/property/bentinck.jpeg"),
  lincoln: require("./media/property/lincoln.jpeg"),
  thenova: require("./media/property/thenova.jpeg"),
  studiowhitecity: require("./media/property/studiowhitecity.jpeg"),
  blackfriars3200: require("./media/property/blackfriars3200.jpeg"),
  clunymews4200: require("./media/property/3clunymews4200.jpeg"),
  legacy: require("./media/property/legacy.jpeg"),
  copse: require("./media/property/39copse.jpeg"),
  allium1730: require("./media/property/allium1730.jpeg"),
  clunymews3200: require("./media/property/clunymews3200.jpeg"),
  salutation: require("./media/property/salutation.jpeg"),
  jubilee3000: require("./media/property/jubilee3000.jpeg"),
  jubilee3300: require("./media/property/jubilee3300.jpeg"),
  parkside: require("./media/property/parkside.jpeg"),
  basil: require("./media/property/basil.jpeg"),
  dingley: require("./media/property/dingley.jpeg"),

  mountpleasant: require("./media/property/mountpleasant.jpeg"),
  jubileewalk3500: require("./media/property/jubileewalk3500.jpeg"),
  jubileewalk3300: require("./media/property/jubileewalk3300.jpeg"),
  viaductgardens4333: require("./media/property/viaductgardens4333.jpeg"),
  viaductgardens4500: require("./media/property/viaductgardens4500.jpeg"),
  marshwall: require("./media/property/marshwall.jpeg"),
  paragonsquare: require("./media/property/paragonsquare.jpeg"),
  paragonsquare5000: require("./media/property/paragonsquare5000.jpeg"),

  sunstreet: require("./media/property/sunstreet.jpeg"),
  coster: require("./media/property/coster.jpeg"),
  panpeninsula: require("./media/property/panpeninsula.jpeg"),

  millbank60002: require("./media/property/millbank60002.jpeg"),
  jubilee3500: require("./media/property/jubilee3500.jpeg"),
  hemlock: require("./media/property/hemlock.jpeg"),
  clunymews4500: require("./media/property/clunymews4500.jpeg"),
  clunymews3500: require("./media/property/clunymews3500.jpeg"),

  conquest: require("./media/property/conquest.jpeg"),
  onetheelephant: require("./media/property/one-the-elephant.jpeg"),
  darter: require("./media/property/darter.jpeg"),

  empire: require("./media/property/empire.jpeg"),
  blackfriars3000: require("./media/property/blackfriars3000.jpeg"),
  darter2350: require("./media/property/darter2350.jpeg"),
  handley: require("./media/property/handley.jpeg"),
  harcourt: require("./media/property/harcourt.jpeg"),
  oncleveland: require("./media/property/oncleveland.jpeg"),
  hartingtons: require("./media/property/hartingtons.jpeg"),

  kidbrookevillageblackheath: require("./media/property/kidbrookevillageblackheath.jpeg"),
  damactower9966: require("./media/property/damactower9966.jpeg"),
  ovalvillage3800: require("./media/property/ovalvillage3800.jpeg"),
  ovalvillage2800: require("./media/property/ovalvillage2800.jpeg"),
  ovalvillage2900: require("./media/property/ovalvillage2900.jpeg"),
  parkdrive3042: require("./media/property/parkdrive3042.jpeg"),
  chenistongarden24999: require("./media/property/chenistongarden24999.jpeg"),
  lincolnsquare6656: require("./media/property/lincolnsquare6656.jpeg"),
  woodberrydown1650: require("./media/property/woodberrydown1650.jpeg"),

  damac0210: require("./media/property/damac0210.jpeg"),
  oval02103800: require("./media/property/oval02103800.jpeg"),
  oval02102800: require("./media/property/oval02102800.jpeg"),
  cheniston0210: require("./media/property/cheniston0210.jpeg"),
  fairbourne0210: require("./media/property/fairbourne0210.jpeg"),
  watkin0210: require("./media/property/watkin0210.jpeg"),
  albany0210: require("./media/property/albany0210.jpeg"),
  union0210: require("./media/property/union0210.jpeg"),
  kidbrooke0210: require("./media/property/kidbrooke0210.jpeg"),
  cluny0210: require("./media/property/cluny0210.jpeg"),
  onezero0210: require("./media/property/onezero0210.jpeg"),
  rw90jch: require("./media/property/rw90jch.jpeg"),
  rw90ih: require("./media/property/rw90ih.jpeg"),
  opus0224: require("./media/property/opus0224.jpeg"),
  watkin0224: require("./media/property/watkin0224.jpeg"),
  edenplace0403: require("./media/property/edenplace0403.jpeg"),
  gasholder0403: require("./media/property/gasholder0403.jpeg"),
};
